<template>
  <main class="auth">
    <form class="auth__form" @submit.prevent="login">
      <span class="auth__title">Вход</span>
      <InputComponent
        title="Логин"
        v-model="form.email.value"
        :errors="form.email.errors"
        :messages="form.email.messages"
      />
      <InputComponent
        title="Пароль"
        type="password"
        v-model="form.password.value"
        :errors="form.password.errors"
        :messages="form.password.messages"
      />
      <div class="auth__actions">
        <button type="submit" class="btn btn--md btn--main" :disabled="loading">
          <LoadingIndicator title="Вход" v-show="loading" />
          <span v-show="!loading">Войти</span>
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import USER_LOGIN from "@/graphql/auth/UserLogin.graphql";
import LoadingIndicator from "components/LoadingIndicator.vue";
import { parseGqlErrors, resetErrors } from "@/utils.js";

export default {
  name: "LoginPage",
  data: () => ({
    loading: false,
    form: {
      email: {
        value: null,
        errors: [],
        messages: [],
      },
      password: {
        value: null,
        errors: [],
        messages: [],
      },
    },
  }),
  methods: {
    parseGqlErrors,
    resetErrors,
    login() {
      if (!this.loading) {
        this.resetErrors();
        this.loading = true;
        this.$apollo
          .mutate({
            mutation: USER_LOGIN,
            variables: {
              email: this.form.email.value,
              password: this.form.password.value,
            },
          })
          .then(({ data }) => {
            if (data && data.UserLogin) {
              // Сохраняю токен в сессии
              fetch("/token", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json;charset=utf-8",
                },
                body: JSON.stringify({
                  token: data.UserLogin.token,
                }),
              }).then(() => {
                location.reload();
              });
            }
          })
          .catch(({ graphQLErrors }) => {
            this.parseGqlErrors(graphQLErrors);
            this.loading = false;
          });
      }
    },
  },
  components: {
    LoadingIndicator,
    InputComponent,
  },
};
</script>

<style lang="stylus">
.auth {
  display flex
  justify-content center
  align-items center
  margin auto
  width 100%
  gap 10px

  &__title {
    font-size 1.75rem
    text-align center
    font-weight 500
  }

  &__form {
    display grid
    justify-content stretch
    grid-gap 10px
    border-radius var(--radius)
    max-width 300px
    width 100%
    background var(--gray)
    min-height 300px
    padding 24px
    box-shadow 0 0 0 1px var(--gray-500)
  }

  &__actions {
    display flex
    justify-content flex-start
  }
}
</style>
